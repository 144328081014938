import { loaders } from '@loaders/employeeLoaders';
import { BookingSummary } from '@pages/booking/BookingSummary';
import { ConfirmBooking } from '@pages/booking/ConfirmBooking';
import { PickTimeslot, PickTimeslotErrors } from '@pages/booking/PickTimeslot';
import { EmblaMethodChart } from '@pages/dk/EmblaMethod/EmblaMethodChart';
import { ProbablyEligible } from '@pages/dk/ProbablyEligible';
import { ProgramOffer } from '@pages/dk/ProgramOffer';
import { ComorbidityQuestions } from '@pages/shared/ComorbidityQuestions';
import { ConfirmPhoneNumber, ConfirmPhoneNumberErrors } from '@pages/shared/ConfirmPhoneNumber';
import { CurrentGLP1Medication } from '@pages/shared/CurrentGLP1Medication';
import { EligibilityQuestions } from '@pages/shared/EligibilityQuestions';
import { HeardAbout } from '@pages/shared/HeardAbout';
import { HeightWeight } from '@pages/shared/HeightWeight';
import { NotEligible } from '@pages/shared/NotEligible';
import { PageTemplates } from '@pages/shared/pageTemplates';
import { Registration, RegistrationErrors } from '@pages/shared/Registration';
import api from '@shared/api';
import { funnelConfiguration, funnelPage } from '@shared/funnel-engine';
import { calculateBmi, resolveIntl } from '@shared/helpers';
import { anyTicked, onlyTickedOneOrMore } from '@util/helpers';
import { performPasswordLessLogin, startPasswordlessLogin } from '@util/passwordLessLogin';
import IFunnelContext, { NotEligibleReason, ProductSelection } from 'src/FunnelContext';

import { saveData } from './handlers/data';
import { registrationExitHandler } from './handlers/registration';
import { onPageEntry, onPageExit } from './handlers/tracking';

import { t } from '@t';

export const createFunnelV3DKConfig = (initialContext: Partial<IFunnelContext>) =>
  funnelConfiguration({
    replay: !!initialContext?.userSession,
    handlers: {
      onPageExit: ({ page, context, data, isReplay }) => {
        if (isReplay) return;

        onPageExit(page, context, data);
        saveData(data, context);
      },
      onPageEntry: async ({ page, context, isReplay }) => {
        if (isReplay) return;

        onPageEntry(page, context);
      },
      onComplete: async ({ page, data: { information }, isReplay }) => {
        if (isReplay) return;

        if (page === 'booking/confirmation') {
          await api.SignupFunnelFinalizeV3({
            data: {
              heardAboutEmbla: information.heard_about.value,
              glp1: information.glp1.value,
              comorbidity: information.comorbidity,
              eligibility: information.eligibility,
              height_weight: information.height_weight,
              emblaShareCode: information.registration.coupon,
            },
            terminal: true,
          });
        }
      },
    },
    config: {
      information: {
        intro: funnelPage(
          PageTemplates.TitleTextSections({
            sections: [{ title: t('emblaIsTheBest'), image: true }],
            showTrustpilot: true,
          }),
          { pageWidth: 480 },
        ),
        registration: funnelPage(Registration, {
          onExit: registrationExitHandler,
        }),
        heard_about: funnelPage(HeardAbout),

        confirm_phone_number: funnelPage(ConfirmPhoneNumber, {
          onEntry: async ({
            context: {
              userSession: { phoneNumber },
            },
          }) => {
            await startPasswordlessLogin(phoneNumber);
          },
          onExit: async ({ funnelApi }) => {
            const { User } = await api.UserGet();
            const activeScreening = User?.activeScreening;
            if (activeScreening) {
              funnelApi.updateContext({ appointmentId: activeScreening.id });
            }
          },
          actions: {
            confirmPhoneNumber: async (phoneNumber: string, code: string, { funnelApi }) => {
              const result = await performPasswordLessLogin(phoneNumber, code);
              if (!result) {
                return false;
              }

              funnelApi.updateContext({
                userSession: {
                  phoneNumber,
                  token: result.token,
                  refreshToken: result.refreshToken,
                  userId: result.userId,
                  phoneConfirmed: true,
                },
                flowVariant: 'booking',
              });
              sessionStorage.setItem('token', result.token);

              return true;
            },
            sendSMSCode: async (phoneNumber) => {
              await startPasswordlessLogin(phoneNumber);
            },
          },
        }),
        height_weight: funnelPage(HeightWeight, {
          onExit: async ({ data: { height, weight, unitSystem }, funnelApi }) => {
            const bmi = calculateBmi(weight, height);
            const getLowBmiReason = (): NotEligibleReason => {
              if (bmi < 18.5) return 'very-low-bmi';
              if (bmi < 23) return 'low-bmi';
            };

            funnelApi.updateContext({
              measurements: {
                bmi,
                height,
                weight,
                preferredUnitSystem: unitSystem,
              },
              notEligibleReason: getLowBmiReason(),
            });
          },
          loader: ({ context: { notEligibleReason } }) => {
            if (!notEligibleReason) {
              return loaders.DK.comorbidities;
            }
          },
        }),
        comorbidity: funnelPage(ComorbidityQuestions, {
          onExit: async ({
            context: {
              measurements: { bmi },
            },
            data,
            funnelApi,
          }) => {
            const fallbackProduct = bmi >= 30 ? ProductSelection.MedicationAndCoaching : ProductSelection.CoachingOnly;

            const product = anyTicked(data) ? ProductSelection.MedicationAndCoaching : fallbackProduct;

            funnelApi.updateContext({
              initialEligibility: product,
            });
          },
        }),
        eligibility: funnelPage(EligibilityQuestions, {
          onExit: async ({ funnelApi, context: { initialEligibility }, data }) => {
            if (data.alcohol_drugs || data.eatingdisorder) {
              funnelApi.updateContext({
                notEligibleReason: data.eatingdisorder ? 'eating-disorder' : 'addiction',
                eligibleFor: undefined,
              });
              return;
            } else if (anyTicked(data) && !onlyTickedOneOrMore(data, ['diabetesType2', 'cancer'])) {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.CoachingOnly,
                selectedProduct: ProductSelection.CoachingOnly,
                notEligibleReason: undefined,
              });
            } else {
              // else just reset back to whatever was seeded
              funnelApi.updateContext({
                eligibleFor: initialEligibility,
                selectedProduct: initialEligibility,
                notEligibleReason: undefined,
              });
            }
          },
        }),
        thank_you: funnelPage(NotEligible),
        glp1: funnelPage(CurrentGLP1Medication, {
          onExit: async ({ context, data, funnelApi }) => {
            const getLowBmiReason = (): NotEligibleReason => {
              if (context.measurements.bmi < 18.5) return 'very-low-bmi';
              if (context.measurements.bmi < 27) return 'low-bmi';
            };

            if (context.measurements.bmi < 27) {
              if (data.value) {
                funnelApi.updateContext({
                  eligibleFor: ProductSelection.MedicationTaperingOff,
                  selectedProduct: ProductSelection.MedicationTaperingOff,
                  onGLP1Medication: data.value,
                });
              } else {
                funnelApi.updateContext({
                  eligibleFor: undefined,
                  notEligibleReason: getLowBmiReason(),
                  onGLP1Medication: data.value,
                });
              }
            }
          },
          loader: ({ context }) => {
            if (context.measurements.bmi >= 27) return loaders.DK.youQualify;
          },
        }),
        tapering_off: funnelPage(ProbablyEligible),
      },
      booking: {
        method: funnelPage(ProgramOffer, {
          onEntry: async ({ funnelApi, context }) => {
            const { locale } = resolveIntl();
            const priceInfos = await api.FinancialPrices({
              locale,
              promoCode: context.prefilledDiscountCode,
            });
            funnelApi.updateContext({
              priceInfos: priceInfos.FinancialPrices,
              selectedProduct: context.selectedProduct,
            });
          },
          onExit: async ({ data, funnelApi }) => {
            if (data.productChanged) {
              funnelApi.updateContext({
                selectedProduct: data.selectedProduct,
              });
            }
          },
        }),
        prediction: funnelPage(EmblaMethodChart),
        pick_timeslot: funnelPage(PickTimeslot, {
          onEntry: async ({ funnelApi }) => {
            const results = await api.BookableScreeningAppointments();
            funnelApi.updateContext({
              bookableAppointments: results.BookableScreeningAppointments || [],
            });
          },
          onExit: async ({ data: { selectedTimeslot }, funnelApi }) => {
            funnelApi.updateContext({ selectedTimeslot });
          },
        }),
        confirm_booking: funnelPage(ConfirmBooking, {
          onExit: async ({ funnelApi, context: { userSession, selectedTimeslot, embedded } }) => {
            if (embedded || userSession?.phoneConfirmed) {
              const result = await api.AppointmentCreate({
                reason: 'SCREENING',
                calendarId: selectedTimeslot.calendarId,
                startTime: selectedTimeslot.startTime,
                endTime: selectedTimeslot.endTime,
                userId: userSession.userId,
              });
              funnelApi.updateContext({
                appointmentId: result.AppointmentCreate.id,
              });
            }
          },
        }),
        confirm_phone: funnelPage(ConfirmPhoneNumber, {
          onEntry: async ({ context }) => {
            await api.UserStartIdConfirmationSMS({
              phoneNumber: context.userSession.phoneNumber,
            });
          },
          onExit: async ({ funnelApi, context: { selectedTimeslot, userSession } }) => {
            funnelApi.updateContext({
              userSession: {
                ...userSession,
                phoneConfirmed: true,
              },
            });

            try {
              const result = await api.AppointmentCreate({
                reason: 'SCREENING',
                calendarId: selectedTimeslot.calendarId,
                startTime: selectedTimeslot.startTime,
                endTime: selectedTimeslot.endTime,
                userId: userSession.userId,
              });
              funnelApi.updateContext({
                appointmentId: result.AppointmentCreate.id,
              });
            } catch (err: unknown) {
              if (err?.toString().includes('CODE_3001_APPOINTMENT_DATE_NOT_AVB')) {
                return ConfirmPhoneNumberErrors.AppointmentNotAvailable;
              }
              throw err;
            }
          },
          actions: {
            confirmPhoneNumber: async (phoneNumber, code) => {
              try {
                const result = await api.UserCompleteIdConfirmationSMS({
                  phoneNumber,
                  code,
                });
                return result.UserCompleteIdConfirmation.success;
              } catch (error) {
                return false;
              }
            },
            sendSMSCode: async (phoneNumber, { context, funnelApi }) => {
              await api.UserStartIdConfirmationSMS({ phoneNumber });
              funnelApi.updateContext({
                userSession: {
                  ...context.userSession,
                  phoneNumber,
                },
              });
            },
          },
        }),
        confirmation: funnelPage(BookingSummary, { terminal: true }),
      },
    },
    routingRules: {
      information: {
        registration: ({ context: { flowVariant }, error }) => {
          if (error === RegistrationErrors.PhoneOrEmailAlreadyInUse) {
            return 'information/registration';
          }
          if (flowVariant === 'phone-already-registered') return 'information/confirm_phone_number';
          else return 'information/heard_about';
        },
        heard_about: () => {
          return 'information/height_weight';
        },
        confirm_phone_number: ({ context: { appointmentId } }) => {
          if (appointmentId) {
            return 'booking/confirmation';
          }
        },
        height_weight: ({ context: { notEligibleReason } }) => {
          if (['very-low-bmi', 'low-bmi'].includes(notEligibleReason)) {
            return 'information/thank_you';
          }
        },
        eligibility: ({ context }) => {
          if (context.eligibleFor === undefined) {
            return 'information/thank_you';
          }
          return 'information/glp1';
        },
        glp1: ({ context }) => {
          if (context.eligibleFor === undefined) {
            return 'information/thank_you';
          }
          if (context.eligibleFor === ProductSelection.MedicationTaperingOff) {
            return 'information/tapering_off';
          }
          return 'booking/method';
        },
        tapering_off: () => {
          return 'booking/pick_timeslot';
        },
      },
      booking: {
        method: ({ data }) => {
          if (data.productChanged) {
            return 'booking/method';
          }
        },
        confirm_booking: ({ context }) => {
          if (context.userSession?.phoneConfirmed || context.embedded) {
            return 'booking/confirmation';
          }
          return 'booking/confirm_phone';
        },
        confirm_phone: ({ error }) => {
          if (error === ConfirmPhoneNumberErrors.AppointmentNotAvailable) {
            return {
              route: 'booking/pick_timeslot',
              error: PickTimeslotErrors.AppointmentNotAvailable,
            };
          }
        },
      },
    },
    defaultPageWidth: 720,
    defaultLoader: {
      component: () => null,
      progressBar: false,
    },
    initialContext,
  });
