import { Card, Text } from '@mantine/core';
import { CountryIso3166 } from '@shared/gql/sdk';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductSelection } from 'src/FunnelContext';
import { resolveIntl } from 'src/resolveIntl';

interface IProps {
  selectedProduct: ProductSelection;
  productChanged: boolean;
  onClick: () => void;
}

export const SwitchProgram: FunctionComponent<IProps> = ({ selectedProduct, productChanged, onClick }) => {
  const { t } = useTranslation();
  const { country } = resolveIntl();
  if (selectedProduct === ProductSelection.CoachingOnly && !productChanged) {
    return null;
  }
  return (
    <Card style={{ cursor: onClick ? 'pointer' : 'auto' }} bg="transparent" onClick={onClick}>
      <Text fw={600} data-secondary>
        {selectedProduct === ProductSelection.CoachingOnly
          ? t('notInterestedInCoachingOnly')
          : t('notInterestedInMedication')}
      </Text>
      <Text fw={400} data-secondary>
        {selectedProduct === ProductSelection.CoachingOnly
          ? country === CountryIso3166.Us
            ? t('seeEmblaThriveMD')
            : t('seeCoachingAndMedicationProgram')
          : country === CountryIso3166.Us
            ? t('seeEmblaThrive')
            : t('seeCoachingAndMedicationProgram')}
      </Text>
    </Card>
  );
};
