import { Card, Divider, Flex, Group, Paper, Stack, Text, TextProps } from '@mantine/core';
import { CouponDiscount, EligibilityType, FinancialPrice } from '@shared/gql/sdk';
import { formatAmount, formatInterval } from '@shared/helpers';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Icon } from './Icon';

type Item = {
  label: string;
  value: string;
  valueTextProps?: TextProps;
};

export const ReceiptUS: React.FC<{ price: FinancialPrice }> = ({ price }) => {
  const { t } = useTranslation();

  const totalAmountFormatted = useMemo(() => {
    const { amount, campaignAmount, product } = price;
    const baseAmount = campaignAmount || amount;
    const totalExternalFees = product.externalServiceFees?.reduce((acc, fee) => acc + fee.amount, 0) || 0;
    return formatAmount(baseAmount + totalExternalFees, price.currency);
  }, [price]);

  const paymentItems = useMemo(() => {
    const { amount, currency, campaignAmount, campaignDiscount, paymentIntervalCount } = price;
    const intervalCount = parseInt(paymentIntervalCount, 10);
    const subtotalLabel = `${t('subtotal')}${intervalCount > 1 ? ` (${intervalCount} ${t('months')})` : ''}`;
    const basePrice = formatAmount(amount / intervalCount, currency);
    const subtotalValue = formatAmount(campaignAmount || amount, currency);

    if (campaignDiscount) {
      const discountLabel = (discount: CouponDiscount, intervalCount: number, currency: string) => {
        const { percentage, amount, name } = discount;

        if (percentage) {
          return t('percentDiscount', { percentage });
        } else if (amount) {
          return t('amountDiscount', { amount: formatAmount(amount * 100, currency) });
        } else {
          return name;
        }
      };

      return [
        {
          label: t('regularPrice'),
          value: `${basePrice}/${formatInterval(price, 'per')}`,
          valueTextProps: { td: 'line-through' },
        },
        {
          label: discountLabel(campaignDiscount, intervalCount, currency),
          value: `${formatAmount(campaignAmount / intervalCount, price.currency)}/${formatInterval(price, 'per')}`,
        },
        { label: subtotalLabel, value: subtotalValue },
      ];
    }

    return [
      { label: t('price'), value: basePrice },
      { label: subtotalLabel, value: subtotalValue },
    ];
  }, [t, price]);

  const externalFeeItems = useMemo(
    () =>
      price.product.externalServiceFees?.map((fee) => ({
        label: fee.product.name,
        value: formatAmount(fee.amount, fee.currency),
      })),
    [price.product.externalServiceFees],
  );

  const renderRow = ({ label, value, valueTextProps }: Item) => (
    <Group key={label} justify="space-between">
      <Text>{label}</Text>
      <Text fw={500} {...valueTextProps}>
        {value}
      </Text>
    </Group>
  );
  const translationArgs = price.transitionsTo
    ? {
        intervalCount: price.paymentIntervalCount,
        amount: `${formatAmount(
          price.transitionsTo.campaignAmount || price.transitionsTo.amount,
          price.transitionsTo.currency,
        )}/${formatInterval(price, 'per')}`,
      }
    : null;

  return (
    <Card>
      <Text fw="bold">{price.name}</Text>
      <Stack gap="md" my="lg">
        <Divider />
        {paymentItems.map(renderRow)}
        {externalFeeItems.length > 0 && (
          <>
            <Divider my="0" />
            {externalFeeItems.map(renderRow)}
          </>
        )}
        <Divider />
        <Group justify="space-between">
          <Text fw="bold">{t('total')}</Text>
          <Text fw="bold">{totalAmountFormatted}</Text>
        </Group>
      </Stack>

      <Card radius={'md'} data-background-dark>
        {price.transitionsTo ? (
          <Group gap={'sm'}>
            <Paper radius={9} w={18} h={18}>
              <Flex justify={'center'} align={'center'} w={'100%'} h={'100%'}>
                <Icon name="icon-fi-rr-info" size={10} />
              </Flex>
            </Paper>
            <Text data-secondary>
              {price.product.eligibilityType === EligibilityType.CoachingAndMedication
                ? t('afterIntervalMonthsYouWillBeCharged.medication', translationArgs)
                : t('afterIntervalMonthsYouWillBeCharged', translationArgs)}
            </Text>
          </Group>
        ) : null}
        {price.product.eligibilityType === EligibilityType.CoachingAndMedication && (
          <Text data-secondary>
            <Trans
              i18nKey={'importantMedicationCostsArePaidSeparatelyAtThePharmacy'}
              components={{ b: <span style={{ fontWeight: 700 }} /> }}
            />
          </Text>
        )}
      </Card>
    </Card>
  );
};
