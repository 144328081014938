import { env } from '@env';
import api, { isAuthenticated } from '@shared/api';
import { FunnelEngineData } from '@shared/funnel-engine';
import dayjs from 'dayjs';
import IFunnelContext from 'src/FunnelContext';

import { createPartnerFunnelConfig } from '../config';

export type Data = FunnelEngineData<ReturnType<typeof createPartnerFunnelConfig>['config']>;

export const saveData = async (data: Data, context: IFunnelContext, terminal = false) => {
  if (!isAuthenticated()) return;
  try {
    const { registration, checkout } = data;
    await api.SignupFunnelFinalizeV3({
      data: {
        partnerClientSessionId: context.partnerClientSession?.id,
        glp1: registration.glp1.value,
        comorbidity: registration.comorbidity,
        eligibility: registration.eligibility,
        signupCurrentMedication: registration.current_medications,
        height_weight: registration.height_weight,
        emblaShareCode: context.noUserDiscount ? env.partnerCoupon : registration.registration?.coupon,
        notEligibleReason: context.notEligibleReason,
        address: checkout.checkout.address,
        dateOfBirth: registration.birth_date.value ? dayjs(registration.birth_date.value).format('YYYY-MM-DD') : null,
        signupPregnantOrBreastfeeding: registration.pregnancy.value,
        sexAssignedAtBirth: registration.sex_assigned.value,
      },
      terminal,
    });
  } catch (e) {
    console.warn('Failed to save data');
  }
};
