import { FunnelRoutes } from '@shared/funnel-engine';
import { TrackingEventName } from '@shared/gql/sdk';
import { trackingService } from '@shared/TrackingContext';
import IFunnelContext, { ProductSelection } from 'src/FunnelContext';

import { Data } from './data';
import { createPartnerFunnelConfig } from '../config';

type Pages = FunnelRoutes<ReturnType<typeof createPartnerFunnelConfig>['config']>;

const { trackEvent, pushGTMEvent } = trackingService;

const mapEligibleToTrackingEligibility = (eligibleFor: ProductSelection) => {
  switch (eligibleFor) {
    case ProductSelection.MedicationAndCoaching:
      return 'MedicineAndCoaching';
    case ProductSelection.CoachingAndMaybeMedication:
      return 'CoachingAndMaybeMedicine';
    case ProductSelection.CoachingOnly:
      return 'CoachingOnly';
    default:
      return 'NotEligible';
  }
};

export const onPageEntry = (page: Pages, context: IFunnelContext) => {
  pushGTMEvent('pageview', { funnel_page: page });
  switch (page) {
    case 'intro/pharma_method':
      trackEvent(TrackingEventName.SignupStarted);
      break;
    case 'registration/registration':
      trackEvent('signup_registration_presented');
      break;
    case 'checkout/confirm_membership_phone':
      trackEvent(TrackingEventName.SignupBookingcodeAsked);
      break;
    case 'checkout/recommendation':
    case 'checkout/choose_program': {
      trackEvent(TrackingEventName.SignupScreeningCompleted, {
        bmi: context.measurements.bmi,
        eligibility_result: mapEligibleToTrackingEligibility(context.eligibleFor),
      });
      trackEvent('signup_membership_recommended');
      break;
    }
    case 'checkout/confirm_phone':
      trackEvent('signup_bookingcode_entered');
      break;
    case 'checkout/confirmation_membership': {
      trackEvent(TrackingEventName.SignupCheckoutCompleted);
      break;
    }
    case 'checkout/confirmation': {
      if (context.flowVariant === 'booking') {
        trackEvent(TrackingEventName.SignupConsultationConfirmed, {
          bookedAppointmentId: context.appointmentId,
        });
        pushGTMEvent('screening_booked', {
          bookedAppointmentId: context.appointmentId,
        });
      } else if (context.flowVariant === 'callback') {
        trackEvent('signup_callback_confirmed');
      }
      break;
    }
    case 'checkout/pick_timeslot':
      trackEvent(TrackingEventName.SignupTimeslotPresented);
      break;
  }
};

export const onPageExit = (page: Pages, context: IFunnelContext, data: Data) => {
  // data.program.biggest_lie = context.biggestLie;
  switch (page) {
    case 'intro/pharma_method':
      trackEvent('signup_intro_presented');
      break;
    case 'intro/approach':
      trackEvent('signup_method_presented');
      break;
    case 'intro/results':
      trackEvent('signup_research_presented');
      break;
    case 'intro/our_programs':
      trackEvent('signup_offers_presented');
      break;

    case 'registration/registration':
      if (context.userSession?.userId) {
        trackEvent(TrackingEventName.SignupRegistered);
        pushGTMEvent('user_registered', {
          userId: context.userSession.userId,
        });
      }
      break;
    case 'registration/state_of_residency':
      trackEvent('signup_state_entered', data.registration.state_of_residency);
      break;
    case 'registration/birth_date':
      trackEvent('signup_dateofbirth_entered');
      break;
    case 'registration/height_weight': {
      trackEvent(TrackingEventName.SignupHeightWeightEntered, {
        bmi: context.measurements.bmi,
        weight: context.measurements.weight,
        unitSystem: context.measurements.preferredUnitSystem,
      });
      trackEvent(TrackingEventName.SignupEligibilityQuestionsAnswered);
      const reasons = [];
      if (context.notEligibleReason === 'addiction') {
        reasons.push('AlcoholDrugs');
      }
      if (context.notEligibleReason === 'eating-disorder') {
        reasons.push('EatingDisorder');
      }
      if (reasons.length > 0) {
        trackEvent(TrackingEventName.SignupScreeningCompleted, {
          bmi: context.measurements.bmi,
          eligibility_result: mapEligibleToTrackingEligibility(context.eligibleFor),
          reasons,
        });
      }
      break;
    }
    case 'registration/eligibility':
      trackEvent(TrackingEventName.SignupEligibilityQuestionsAnswered);
      break;
    case 'registration/comorbidity':
      trackEvent(TrackingEventName.SignupComorbidityQuestionsAnswered);
      break;
    case 'registration/sex_assigned':
      trackEvent(TrackingEventName.SignupSexAssignedAnswered);
      break;
    case 'registration/pregnancy':
      trackEvent(TrackingEventName.SignupPregnancyAnswered);
      break;
    case 'registration/current_medications':
      trackEvent(TrackingEventName.SignupMedicationsAnswered);
      break;
    case 'registration/glp1': {
      trackEvent(TrackingEventName.SignupGlp1Answered, {
        GLP1_at_signup: context.onGLP1Medication,
      });
      break;
    }
    case 'checkout/choose_program': {
      trackEvent(TrackingEventName.SignupCheckoutStarted);
      trackEvent('signup_membership_recommended');
      break;
    }
    case 'checkout/confirm_membership_phone':
      trackEvent(TrackingEventName.SignupPurchaseConfirmed);
      break;
    case 'checkout/checkout':
      trackEvent('signup_option_chosen', { flow: context.flowVariant });
      break;

    // checkout flow
    case 'checkout/pay':
      trackEvent(TrackingEventName.SignupCheckoutStarted);
      break;
    case 'checkout/confirmation_membership': {
      if (context.flowVariant === 'booking') {
        trackEvent(TrackingEventName.SignupConsultationConfirmed, {
          bookedAppointmentId: context.appointmentId,
        });
        pushGTMEvent('screening_booked', {
          bookedAppointmentId: context.appointmentId,
        });
      }
      break;
    }
    // booking flow
    case 'checkout/pick_timeslot': {
      trackEvent(TrackingEventName.SignupTimeslotPicked, {
        calendar_id: context.selectedTimeslot.calendarId,
        start_time: context.selectedTimeslot.startTime,
      });
      break;
    }
    case 'checkout/confirm_booking': {
      context.embedded ? trackEvent('signup_booking_confirmed') : null;
      break;
    }
    case 'checkout/confirm_phone':
      trackEvent('signup_bookingcode_entered');
      break;
  }
};
