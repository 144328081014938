import { env } from '@env';
import { loaders } from '@loaders/employeeLoaders';
import { Grid } from '@mantine/core';
import { BookingSummary } from '@pages/booking/BookingSummary';
import { ConfirmBooking } from '@pages/booking/ConfirmBooking';
import { PickTimeslot, PickTimeslotErrors } from '@pages/booking/PickTimeslot';
import { EmblaMethodChart } from '@pages/dk/EmblaMethod/EmblaMethodChart';
import { ProbablyEligible } from '@pages/dk/ProbablyEligible';
import { ProgramOffer } from '@pages/dk/ProgramOffer';
import { PharmaMethod } from '@pages/partner-signup/PharmaMethod';
import { ComorbidityQuestions } from '@pages/shared/ComorbidityQuestions';
import { ConfirmPhoneNumber, ConfirmPhoneNumberErrors } from '@pages/shared/ConfirmPhoneNumber';
import { CurrentGLP1Medication } from '@pages/shared/CurrentGLP1Medication';
import { EligibilityQuestions } from '@pages/shared/EligibilityQuestions';
import { HeardAbout } from '@pages/shared/HeardAbout';
import { NotEligible } from '@pages/shared/NotEligible';
import { gridElement, textElement } from '@pages/shared/pageTemplateHelpers';
import { PageTemplates } from '@pages/shared/pageTemplates';
import { createEnterMeasurementsPage } from '@pages/shared/partner/EnterMeasurements';
import { WhoCanWeHelp } from '@pages/shared/partner/WhoCanWeHelp';
import { Registration, RegistrationErrors } from '@pages/shared/Registration';
import api from '@shared/api';
import { funnelConfiguration, funnelPage } from '@shared/funnel-engine';
import { PartnerClientSessionType, UnitSystem, UserIdConfirmationType } from '@shared/gql/sdk';
import { calculateBmi, resolveIntl } from '@shared/helpers';
import { anyTicked, onlyTickedOneOrMore } from '@util/helpers';
import { performPasswordLessLogin, startPasswordlessLogin } from '@util/passwordLessLogin';
import { Trans } from 'react-i18next';
import IFunnelContext, { NotEligibleReason, ProductSelection } from 'src/FunnelContext';

import { saveData } from './handlers/data';
import { registrationExitHandler } from './handlers/registration';
import { onPageEntry, onPageExit } from './handlers/tracking';

import { t } from '@t';

export const createPartnerFunnelConfig = (initialContext: Partial<IFunnelContext>) =>
  funnelConfiguration({
    handlers: {
      onPageExit: ({ page, context, data, isReplay }) => {
        if (isReplay) return;

        onPageExit(page, context, data);
        saveData(data, context);
      },
      onPageEntry: async ({ page, context, isReplay }) => {
        if (isReplay) return;

        onPageEntry(page, context);
      },
      onComplete: async ({ page, context, data, isReplay }) => {
        if (isReplay) return;

        if (page === 'booking/confirmation') {
          await saveData(data, context, true);
        }
      },
    },
    config: {
      intro: {
        perspective: funnelPage(PharmaMethod),
        how_we_help: funnelPage(
          PageTemplates.TitleText({
            title: t('how_we_help.title'),
            content: (
              <Grid gutter={'md'} justify="center">
                {gridElement('1', <Trans i18nKey="dk.pharmacy.personalHealthCoach" />, 'icon-fi-rr-users')}
                {gridElement('2', <Trans i18nKey="dk.pharmacy.medicationAsSupport" />, 'icon-fi-rr-medicine')}
                {gridElement('3', <Trans i18nKey="dk.pharmacy.accessToMedicalStaff" />, 'icon-fi-rr-comments')}
                {gridElement('4', <Trans i18nKey="dk.pharmacy.appWithTools" />, 'icon-fi-rr-mobile')}
              </Grid>
            ),
          }),
          { pageWidth: 500 },
        ),

        results: funnelPage(
          PageTemplates.TitleText({
            title: t('results.title'),
            content: (
              <Grid gutter={'md'} justify="center">
                {gridElement('1', <Trans i18nKey="averageWeightloss" />, 'icon-fi-rr-scale', 'variant1')}
                {gridElement('2', <Trans i18nKey="lowerDosage" />, 'icon-fi-rr-chat-arrow-down', 'variant2')}
                {gridElement('3', <Trans i18nKey="increasedHappiness" />, 'icon-fi-rr-smile', 'variant3')}
                {gridElement('4', <Trans i18nKey="weightLossRetention" />, 'icon-fi-rr-salad', 'variant4')}
              </Grid>
            ),
          }),
          { pageWidth: 500 },
        ),
        who_can_we_help: funnelPage(WhoCanWeHelp, { pageWidth: 500 }),
        what_can_we_offer: funnelPage(
          PageTemplates.TitleBulletPoints({
            title: t('what_can_we_offer.title'),
            bulletpoints: [
              textElement('freeIntroCall'),
              textElement('takeMeasurements'),
              textElement('accessToCheckups'),
              textElement('accessToApp'),
            ],
          }),
          { pageWidth: 500 },
        ),
      },
      registration: {
        registration: funnelPage(Registration, {
          onExit: registrationExitHandler,
        }),
        heard_about: funnelPage(HeardAbout),
        confirm_phone_number: funnelPage(ConfirmPhoneNumber, {
          onEntry: async ({
            context: {
              userSession: { phoneNumber },
            },
          }) => await startPasswordlessLogin(phoneNumber),
          onExit: async ({ context, funnelApi }) => {
            const { User } = await api.UserGet();

            if (User) {
              const activeScreening = User?.activeScreening;
              if (activeScreening) {
                funnelApi.updateContext({ appointmentId: activeScreening.id });
              }
              const { CreatePartnerClientSession: partnerClientSession } = await api.CreatePartnerClientSession({
                type: PartnerClientSessionType.Signup,
                partnerCode: context.partnerCode,
              });

              funnelApi.updateContext({ partnerClientSession });
            }
          },
          actions: {
            confirmPhoneNumber: async (phoneNumber: string, code: string, { funnelApi }) => {
              const result = await performPasswordLessLogin(phoneNumber, code);
              if (!result) {
                return false;
              }

              sessionStorage.setItem('token', result.token);

              funnelApi.updateContext({
                userSession: {
                  phoneNumber,
                  token: result.token,
                  refreshToken: result.refreshToken,
                  userId: result.userId,
                  phoneConfirmed: true,
                },
                flowVariant: 'booking',
              });

              return true;
            },
            sendSMSCode: startPasswordlessLogin,
          },
        }),
        height_weight: funnelPage(createEnterMeasurementsPage(true), {
          onExit: async ({ data, funnelApi }) => {
            const bmi = calculateBmi(data.weightKg, data.heightCm);
            const getLowBmiReason = (): NotEligibleReason => {
              if (bmi < 18.5) return 'very-low-bmi';
              if (bmi < 23) return 'low-bmi';
            };

            funnelApi.updateContext({
              measurements: {
                bmi,
                height: data.heightCm,
                weight: data.weightKg,
                preferredUnitSystem: UnitSystem.Metric,
              },
              notEligibleReason: getLowBmiReason(),
            });
          },
          loader: ({ context: { notEligibleReason } }) => {
            if (!notEligibleReason) {
              return loaders.DK.comorbidities;
            }
          },
        }),
        comorbidity: funnelPage(ComorbidityQuestions, {
          onExit: async ({
            context: {
              measurements: { bmi },
            },
            data,
            funnelApi,
          }) => {
            const fallbackProduct = bmi >= 30 ? ProductSelection.MedicationAndCoaching : ProductSelection.CoachingOnly;

            const product = anyTicked(data) ? ProductSelection.MedicationAndCoaching : fallbackProduct;

            funnelApi.updateContext({
              initialEligibility: product,
            });
          },
        }),
        eligibility: funnelPage(EligibilityQuestions, {
          onExit: async ({ funnelApi, context: { initialEligibility }, data }) => {
            if (data.alcohol_drugs || data.eatingdisorder) {
              funnelApi.updateContext({
                notEligibleReason: data.eatingdisorder ? 'eating-disorder' : 'addiction',
                eligibleFor: undefined,
              });
              return;
            } else if (anyTicked(data) && !onlyTickedOneOrMore(data, ['diabetesType2', 'cancer'])) {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.CoachingOnly,
                selectedProduct: ProductSelection.CoachingOnly,
                notEligibleReason: undefined,
              });
            } else {
              // else just reset back to whatever was seeded
              funnelApi.updateContext({
                eligibleFor: initialEligibility,
                selectedProduct: initialEligibility,
                notEligibleReason: undefined,
              });
            }
          },
        }),
        thank_you: funnelPage(NotEligible),
        glp1: funnelPage(CurrentGLP1Medication, {
          onExit: async ({ context, data, funnelApi }) => {
            const getLowBmiReason = (): NotEligibleReason => {
              if (context.measurements.bmi < 18.5) return 'very-low-bmi';
              if (context.measurements.bmi < 27) return 'low-bmi';
            };

            if (context.measurements.bmi < 27) {
              if (data.value) {
                funnelApi.updateContext({
                  eligibleFor: ProductSelection.MedicationTaperingOff,
                  selectedProduct: ProductSelection.MedicationTaperingOff,
                });
              } else {
                funnelApi.updateContext({
                  eligibleFor: undefined,
                  notEligibleReason: getLowBmiReason(),
                });
              }
            }
          },
          loader: ({ context }) => {
            if (context.measurements.bmi >= 27) return loaders.DK.youQualify;
          },
        }),
        tapering_off: funnelPage(ProbablyEligible),
      },
      booking: {
        method: funnelPage(ProgramOffer, {
          onEntry: async ({ funnelApi, context }) => {
            const { locale } = resolveIntl();
            const priceInfos = await api.FinancialPrices({
              locale,
              promoCode: env.partnerCoupon,
            });
            funnelApi.updateContext({
              priceInfos: priceInfos.FinancialPrices,
              selectedProduct: context.selectedProduct,
            });
          },
        }),
        prediction: funnelPage(EmblaMethodChart),
        pick_timeslot: funnelPage(PickTimeslot, {
          onEntry: async ({ funnelApi }) => {
            const results = await api.BookableScreeningAppointments();
            funnelApi.updateContext({
              bookableAppointments: results.BookableScreeningAppointments || [],
            });
          },
          onExit: async ({ data: { selectedTimeslot }, funnelApi }) => {
            funnelApi.updateContext({ selectedTimeslot });
          },
        }),
        confirm_booking: funnelPage(ConfirmBooking, {
          onExit: async ({ funnelApi, context: { userSession, selectedTimeslot, embedded } }) => {
            if (embedded || userSession?.phoneConfirmed) {
              const result = await api.AppointmentCreate({
                reason: 'SCREENING',
                calendarId: selectedTimeslot.calendarId,
                startTime: selectedTimeslot.startTime,
                endTime: selectedTimeslot.endTime,
                userId: userSession.userId,
              });
              funnelApi.updateContext({
                appointmentId: result.AppointmentCreate.id,
              });
            }
          },
        }),
        confirm_phone: funnelPage(ConfirmPhoneNumber, {
          onEntry: async ({ context }) => {
            await api.UserStartIdConfirmationSMS({
              phoneNumber: context.userSession.phoneNumber,
            });
          },
          onExit: async ({ funnelApi, context: { selectedTimeslot, userSession } }) => {
            funnelApi.updateContext({
              userSession: {
                ...userSession,
                phoneConfirmed: true,
              },
            });

            try {
              const result = await api.AppointmentCreate({
                reason: 'SCREENING',
                calendarId: selectedTimeslot.calendarId,
                startTime: selectedTimeslot.startTime,
                endTime: selectedTimeslot.endTime,
                userId: userSession.userId,
              });
              funnelApi.updateContext({
                appointmentId: result.AppointmentCreate.id,
              });
            } catch (err: unknown) {
              if (err?.toString().includes('CODE_3001_APPOINTMENT_DATE_NOT_AVB')) {
                return ConfirmPhoneNumberErrors.AppointmentNotAvailable;
              }
              throw err;
            }
          },
          actions: {
            confirmPhoneNumber: async (phoneNumber, code) => {
              try {
                const result = await api.UserCompleteIdConfirmationSMS({
                  phoneNumber,
                  code,
                });
                return result.UserCompleteIdConfirmation.success;
              } catch (error) {
                return false;
              }
            },
            sendSMSCode: async (phoneNumber, { context, funnelApi }) => {
              await api.UserStartIdConfirmationSMS({ phoneNumber });
              funnelApi.updateContext({
                userSession: {
                  ...context.userSession,
                  phoneNumber,
                },
              });
            },
          },
        }),
        confirmation: funnelPage(BookingSummary, {
          terminal: true,
          onEntry: async ({ context, funnelApi }) => {
            if (!context.loginLinkSent) {
              funnelApi.updateContext({ loginLinkSent: true });
              await api.SendLoginLink({
                userId: context.userSession.userId,
                medium: UserIdConfirmationType.Sms,
                session: context.userSession.refreshToken,
              });
            }
          },
          onExit: async () => {
            window.location.href = '/dk/partner';
          },
        }),
      },
    },
    routingRules: {
      registration: {
        registration: ({ context: { flowVariant }, error }) => {
          if (error === RegistrationErrors.PhoneOrEmailAlreadyInUse) {
            return 'registration/registration';
          }
          if (flowVariant === 'phone-already-registered') return 'registration/confirm_phone_number';
          else return 'registration/heard_about';
        },
        heard_about: () => {
          return 'registration/height_weight';
        },
        confirm_phone_number: ({ context: { appointmentId } }) => {
          if (appointmentId) {
            return 'booking/confirmation';
          }
        },
        height_weight: ({ context: { notEligibleReason } }) => {
          if (['low-bmi', 'very-low-bmi'].includes(notEligibleReason)) {
            return 'registration/thank_you';
          }
        },
        eligibility: ({ context }) => {
          if (context.eligibleFor === undefined) {
            return 'registration/thank_you';
          }
          return 'registration/glp1';
        },
        glp1: ({ context }) => {
          if (context.eligibleFor === undefined) {
            return 'registration/thank_you';
          }
          if (context.eligibleFor === ProductSelection.MedicationTaperingOff) {
            return 'registration/tapering_off';
          } else {
            return 'booking/method';
          }
        },
        tapering_off: () => 'booking/pick_timeslot',
      },
      booking: {
        method: ({ data }) => {
          if (data.productChanged) {
            return 'booking/method';
          }
        },
        confirm_booking: ({ context }) => {
          if (context.userSession?.phoneConfirmed || context.embedded) {
            return 'booking/confirmation';
          }
          return 'booking/confirm_phone';
        },
        confirm_phone: ({ error }) => {
          if (error === ConfirmPhoneNumberErrors.AppointmentNotAvailable) {
            return {
              route: 'booking/pick_timeslot',
              error: PickTimeslotErrors.AppointmentNotAvailable,
            };
          }
        },
      },
    },
    defaultPageWidth: 720,
    initialContext,
    defaultLoader: {
      component: () => null,
      progressBar: false,
    },
  });
