import { loaders } from '@loaders/employeeLoaders';
import { Grid } from '@mantine/core';
import { BookingSummary } from '@pages/booking/BookingSummary';
import { ConfirmBooking } from '@pages/booking/ConfirmBooking';
import { PickTimeslot } from '@pages/booking/PickTimeslot';
import { CheckoutConfirmation } from '@pages/checkout/CheckoutConfirmation';
import { CheckoutUS } from '@pages/checkout/CheckoutUS';
import { ConfirmPhoneNumberWithMembershipSummary } from '@pages/checkout/ConfirmPhoneNumberWithMembershipSummary';
import { createPayPage } from '@pages/checkout/Pay';
import { PharmaMethod } from '@pages/partner-signup/PharmaMethod';
import { BirthDate } from '@pages/shared/BirthDate';
import { ComorbidityQuestionsForCheckout } from '@pages/shared/ComorbidityQuestionsForCheckout';
import { ConfirmPhoneNumber, ConfirmPhoneNumberErrors } from '@pages/shared/ConfirmPhoneNumber';
import { CurrentGLP1Medication } from '@pages/shared/CurrentGLP1Medication';
import { CurrentMedications } from '@pages/shared/CurrentMedications';
import { EligibilityQuestionsForCheckout } from '@pages/shared/EligibilityQuestionsForCheckout';
import { gridElement } from '@pages/shared/pageTemplateHelpers';
import { PageTemplates } from '@pages/shared/pageTemplates';
import { Pregnancy } from '@pages/shared/Pregnancy';
import { Registration, RegistrationErrors } from '@pages/shared/Registration';
import { ChooseProgram } from '@pages/us-partner-signup/ChooseProgram';
import { HeightWeight } from '@pages/us-partner-signup/HeightWeight';
import { NotEligible } from '@pages/us-partner-signup/NotEligible';
import { NotTexas } from '@pages/us-partner-signup/NotTexas';
import { OurPrograms } from '@pages/us-partner-signup/OurPrograms';
import { Recommendation } from '@pages/us-partner-signup/Recommendation';
import { SexAssignedUS } from '@pages/us-partner-signup/SexAssigned';
import { StateOfResidency } from '@pages/us-partner-signup/StateOfResidency';
import api from '@shared/api';
import { env } from '@shared/env';
import { FunnelPageData, funnelConfiguration, funnelPage } from '@shared/funnel-engine';
import { PartnerClientSessionType, SexAssignedAtBirth, UnitSystem, UserSignInFlowDecision } from '@shared/gql/sdk';
import { calculateBmi, resolveIntl } from '@shared/helpers';
import { anyTicked, getPriceInfoByProductSelection, onlyTickedOneOrMore } from '@util/helpers';
import { performPasswordLessLogin, startPasswordlessLogin } from '@util/passwordLessLogin';
import dayjs from 'dayjs';
import { Trans } from 'react-i18next';
import IFunnelContext, { NotEligibleReason, ProductSelection } from 'src/FunnelContext';

import { saveData } from './handlers/data';
import { registrationExitHandler } from './handlers/registration';
import { onPageEntry, onPageExit } from './handlers/tracking';

import { t } from '@t';

export const createPartnerFunnelConfig = (initialContext: Partial<IFunnelContext>) =>
  funnelConfiguration({
    handlers: {
      onPageExit: ({ page, context, data, isReplay }) => {
        if (isReplay) return;

        onPageExit(page, context, data);
        saveData(data, context);
      },
      onPageEntry: async ({ page, context, isReplay }) => {
        if (isReplay) return;

        onPageEntry(page, context);
      },
      onComplete: async ({ isReplay }) => {
        if (isReplay) return;
      },
    },
    config: {
      intro: {
        pharma_method: funnelPage(PharmaMethod),
        approach: funnelPage(
          PageTemplates.TitleText({
            title: t('emblaApproach.title'),
            content: (
              <Grid gutter={'md'} justify="center">
                {gridElement('1', <Trans i18nKey="emblaApproach.ongoing" />, 'icon-fi-rr-users')}
                {gridElement('2', <Trans i18nKey="emblaApproach.weightloss" />, 'icon-fi-rr-medicine')}
                {gridElement('3', <Trans i18nKey="emblaApproach.direct" />, 'icon-fi-rr-comments')}
                {gridElement('4', <Trans i18nKey="emblaApproach.healthApp" />, 'icon-fi-rr-mobile')}
              </Grid>
            ),
          }),
          { pageWidth: 500 },
        ),
        results: funnelPage(
          PageTemplates.TitleText({
            title: t('studiesInfo.title'),
            content: (
              <Grid gutter={'md'} justify="center">
                {gridElement('1', <Trans i18nKey="studiesInfo.weightLoss" />, 'icon-fi-rr-scale', 'variant1')}
                {gridElement(
                  '2',
                  <Trans i18nKey="studiesInfo.lowerDosage" />,
                  'icon-fi-rr-chat-arrow-down',
                  'variant2',
                )}
                {gridElement('3', <Trans i18nKey="studiesInfo.members" />, 'icon-fi-rr-smile', 'variant3')}
                {gridElement('4', <Trans i18nKey="studiesInfo.keepWeight" />, 'icon-fi-rr-salad', 'variant4')}
              </Grid>
            ),
          }),
          { pageWidth: 500 },
        ),
        our_programs: funnelPage(OurPrograms, {
          onEntry: async ({ funnelApi, context }) => {
            const { locale } = resolveIntl();
            const priceInfos = await api.FinancialPrices({
              locale,
              promoCode: context.noUserDiscount ? env.partnerCoupon : context.couponCode,
            });
            funnelApi.updateContext({
              priceInfos: priceInfos.FinancialPrices,
            });
          },
        }),
      },
      registration: {
        registration: funnelPage(Registration, {
          onExit: async (props) => {
            const error = await registrationExitHandler(props);
            if (error) return error;

            const {
              data: { coupon },
              funnelApi,
              context,
            } = props;
            const { locale } = resolveIntl();
            const priceInfos = coupon
              ? (
                  await api.FinancialPrices({
                    locale,
                    promoCode: coupon,
                  })
                ).FinancialPrices
              : context.priceInfos;
            funnelApi.updateContext({
              couponCode: coupon,
              priceInfos,
            });
          },
        }),
        state_of_residency: funnelPage(StateOfResidency, {
          onExit: async ({ data, funnelApi }) => {
            funnelApi.updateContext({
              stateOfResidency: data.state,
            });
          },
        }),
        confirm_phone_number: funnelPage(ConfirmPhoneNumber, {
          onEntry: async ({
            context: {
              userSession: { phoneNumber },
            },
          }) => await startPasswordlessLogin(phoneNumber),
          onExit: async ({ context, funnelApi }) => {
            const { User } = await api.UserGet();

            if (User) {
              const activeScreening = User?.activeScreening;
              if (activeScreening) {
                funnelApi.updateContext({ appointmentId: activeScreening.id });
              }
              if (context.partnerCode) {
                const { CreatePartnerClientSession: partnerClientSession } = await api.CreatePartnerClientSession({
                  type: PartnerClientSessionType.Signup,
                  partnerCode: context.partnerCode,
                });

                funnelApi.updateContext({ partnerClientSession });
              }
            }
          },
          actions: {
            confirmPhoneNumber: async (phoneNumber: string, code: string, { funnelApi }) => {
              const result = await performPasswordLessLogin(phoneNumber, code);
              if (!result) {
                return false;
              }

              sessionStorage.setItem('token', result.token);

              funnelApi.updateContext({
                userSession: {
                  phoneNumber,
                  token: result.token,
                  refreshToken: result.refreshToken,
                  userId: result.userId,
                  phoneConfirmed: true,
                },
                flowVariant: 'booking',
              });

              return true;
            },
            sendSMSCode: startPasswordlessLogin,
          },
        }),
        birth_date: funnelPage(BirthDate, {
          onExit: async ({ data, funnelApi }) => {
            const today = dayjs();
            const birthDate = dayjs(data.value);
            const age = today.diff(birthDate, 'year');
            const notEligibleReason = age < 18 || age > 75 ? 'invalid-age' : undefined;

            funnelApi.updateContext({
              notEligibleReason,
              age,
            });

            if (age > 65) {
              funnelApi.updateContext({
                initialEligibility: ProductSelection.CoachingOnly,
              });
            }
          },
        }),
        height_weight: funnelPage(HeightWeight, {
          onExit: async ({ data: { height, weight }, funnelApi }) => {
            const bmi = calculateBmi(weight, height);
            const getLowBmiReason = (): NotEligibleReason => {
              if (bmi < 18.5) return 'very-low-bmi';
              if (bmi < 27) return 'low-bmi';
            };

            funnelApi.updateContext({
              measurements: {
                bmi,
                height,
                weight,
                preferredUnitSystem: UnitSystem.UsCustomary,
              },
              notEligibleReason: getLowBmiReason(),
            });
          },
          loader: ({ data: { height, weight } }) => {
            const bmi = calculateBmi(weight, height);
            if (bmi >= 27) return loaders.US.healthConditions;
          },
        }),
        eligibility: funnelPage(EligibilityQuestionsForCheckout, {
          onExit: async ({ funnelApi, context: { initialEligibility }, data }) => {
            if (data.alcohol_drugs || data.eatingdisorder) {
              funnelApi.updateContext({
                notEligibleReason: data.eatingdisorder ? 'eating-disorder' : 'addiction',
                eligibleFor: undefined,
              });
              return;
            } else if (anyTicked(data) && !onlyTickedOneOrMore(data, ['diabetesType2', 'cancer'])) {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.CoachingOnly,
                selectedProduct: ProductSelection.CoachingOnly,
                notEligibleReason: undefined,
              });
            } else {
              // else just reset back to whatever was seeded
              funnelApi.updateContext({
                eligibleFor: initialEligibility,
                selectedProduct: initialEligibility,
                notEligibleReason: undefined,
              });
            }
          },
        }),
        comorbidity: funnelPage(ComorbidityQuestionsForCheckout, {
          loader: ({
            context: {
              measurements: { bmi },
            },
            data,
          }) => {
            const product = comorbidityQuestionsEvaluation(data, bmi);
            if (product === ProductSelection.CoachingOnly) {
              return loaders.US.weAreGeneratingYourRecommendations;
            }
          },
          onExit: async ({
            context: {
              measurements: { bmi },
            },
            data,
            funnelApi,
          }) => {
            const product = comorbidityQuestionsEvaluation(data, bmi);

            funnelApi.updateContext({
              eligibleFor: product,
              selectedProduct: product,
              notEligibleReason: undefined,
            });
          },
        }),
        sex_assigned: funnelPage(SexAssignedUS),
        pregnancy: funnelPage(Pregnancy, {
          loader: ({ data: { value } }) => {
            if (value) {
              return loaders.US.weAreGeneratingYourRecommendations;
            }
          },
          onExit: async ({ data: { value }, funnelApi }) => {
            if (value) {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.CoachingOnly,
                selectedProduct: ProductSelection.CoachingOnly,
                notEligibleReason: undefined,
              });
            } else {
              funnelApi.updateContext({
                eligibleFor: undefined,
                selectedProduct: undefined,
                notEligibleReason: undefined,
              });
            }
          },
        }),
        current_medications: funnelPage(CurrentMedications, {
          onExit: async ({ data, funnelApi }) => {
            if (data.NONE) {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.MedicationAndCoaching,
                selectedProduct: ProductSelection.MedicationAndCoaching,
                notEligibleReason: undefined,
              });
            } else {
              funnelApi.updateContext({
                eligibleFor: ProductSelection.CoachingOnly,
                selectedProduct: ProductSelection.CoachingOnly,
                notEligibleReason: undefined,
              });
            }
          },
        }),
        glp1: funnelPage(CurrentGLP1Medication, {
          onExit: async ({ data, funnelApi }) => {
            funnelApi.updateContext({
              onGLP1Medication: data.value,
            });
          },
          loader: loaders.US.weAreRewiewingYourAnswers,
        }),
        thank_you: funnelPage(NotEligible),
        not_texas: funnelPage(NotTexas),
      },
      checkout: {
        recommendation: funnelPage(Recommendation, {
          onEntry: async ({
            context: { selectedProduct, priceInfos: initialPriceInfos, noUserDiscount, couponCode },
            funnelApi,
          }) => {
            const { locale } = resolveIntl();
            const priceInfos = initialPriceInfos
              ? initialPriceInfos
              : (
                  await api.FinancialPrices({
                    locale,
                    promoCode: noUserDiscount ? env.partnerCoupon : couponCode,
                  })
                ).FinancialPrices;
            funnelApi.updateContext({
              priceInfos,
              selectedProductPriceInfo: getPriceInfoByProductSelection(selectedProduct, priceInfos),
            });
          },
          onExit: async ({ data, funnelApi, context }) => {
            funnelApi.updateContext({
              selectedProduct: data.selectedProduct,
              selectedProductPriceInfo: getPriceInfoByProductSelection(data.selectedProduct, context.priceInfos),
            });
          },
        }),

        choose_program: funnelPage(ChooseProgram, {
          onEntry: async ({
            context: { selectedProduct, priceInfos: initialPriceInfos, noUserDiscount, couponCode },
            funnelApi,
          }) => {
            const { locale } = resolveIntl();
            const priceInfos = initialPriceInfos
              ? initialPriceInfos
              : (
                  await api.FinancialPrices({
                    locale,
                    promoCode: noUserDiscount ? env.partnerCoupon : couponCode,
                  })
                ).FinancialPrices;
            funnelApi.updateContext({
              priceInfos,
              selectedProductPriceInfo: getPriceInfoByProductSelection(selectedProduct, priceInfos),
            });
          },
          onExit: async ({ data, funnelApi, context }) => {
            funnelApi.updateContext({
              selectedProduct: data.selectedProduct,
              selectedProductPriceInfo: getPriceInfoByProductSelection(data.selectedProduct, context.priceInfos),
            });
          },
        }),
        checkout: funnelPage(CheckoutUS, {
          onExit: async ({ data: { productChanged, selectedProduct, didSelectBooking }, funnelApi, context }) => {
            if (productChanged) {
              funnelApi.updateContext({
                selectedProduct: selectedProduct,
                selectedProductPriceInfo: getPriceInfoByProductSelection(selectedProduct, context.priceInfos),
                flowVariant: didSelectBooking ? 'booking' : 'checkout',
              });
            }
          },
        }),
        pay: funnelPage(createPayPage(false), {
          onEntry: async ({ funnelApi, context }) => {
            const subscriptionSetupResponse = await api.SubscriptionSetup({
              priceId: context.selectedProductPriceInfo.id,
            });
            funnelApi.updateContext({
              stripeCredentials: subscriptionSetupResponse.UserSubscriptionSetup,
            });
          },
        }),
        confirm_membership_phone: funnelPage(ConfirmPhoneNumberWithMembershipSummary, {
          onEntry: async ({
            context: {
              userSession: { phoneNumber },
              flowVariant,
            },
          }) => {
            if (flowVariant === 'phone-already-registered') {
              await startPasswordlessLogin(phoneNumber);
            } else {
              await api.UserStartIdConfirmationSMS({ phoneNumber });
            }
          },
          onExit: async ({ data, funnelApi, context: { userSession, flowVariant } }) => {
            const { phoneChanged, userPhoneNumber } = data;
            let updatedFlowVariant = flowVariant;
            if (phoneChanged) {
              const decisionResult = await api.UserGetSignInFlow({
                phoneNumber: userPhoneNumber,
              });
              if (decisionResult.UserGetSignInFlow.decision === UserSignInFlowDecision.Continue) {
                updatedFlowVariant = 'phone-already-registered';
              }
            }

            funnelApi.updateContext({
              flowVariant: updatedFlowVariant,
              userSession: {
                ...userSession,
                phoneNumber: userPhoneNumber,
                phoneConfirmed: true,
              },
            });
          },
          actions: {
            confirmPhoneNumber: async (phoneNumber: string, code: string, { funnelApi, context: { flowVariant } }) => {
              if (flowVariant === 'phone-already-registered') {
                const result = await performPasswordLessLogin(phoneNumber, code);
                if (result) {
                  funnelApi.updateContext({
                    userSession: {
                      phoneNumber,
                      token: result.token,
                      refreshToken: result.refreshToken,
                      userId: result.userId,
                      phoneConfirmed: true,
                    },
                  });
                  return true;
                } else {
                  return false;
                }
              } else {
                try {
                  const result = await api.UserCompleteIdConfirmationSMS({
                    phoneNumber,
                    code,
                  });
                  return result.UserCompleteIdConfirmation.success;
                } catch (error) {
                  return false;
                }
              }
            },
            sendSMSCode: async (phoneNumber, { context }) => {
              const { flowVariant } = context;
              if (flowVariant === 'phone-already-registered') {
                await startPasswordlessLogin(phoneNumber);
              } else {
                await api.UserStartIdConfirmationSMS({ phoneNumber });
              }
            },
          },
        }),
        confirmation_membership: funnelPage(CheckoutConfirmation, { terminal: true }),
        pick_timeslot: funnelPage(PickTimeslot, {
          onEntry: async ({ funnelApi }) => {
            const results = await api.BookableScreeningAppointments();
            funnelApi.updateContext({
              bookableAppointments: results.BookableScreeningAppointments || [],
            });
          },
          onExit: async ({ data: { selectedTimeslot }, funnelApi }) => {
            funnelApi.updateContext({ selectedTimeslot });
          },
        }),
        confirm_booking: funnelPage(ConfirmBooking, {
          onExit: async ({ funnelApi, context: { userSession, selectedTimeslot, embedded } }) => {
            if (embedded || userSession?.phoneConfirmed) {
              const result = await api.AppointmentCreate({
                reason: 'SCREENING',
                calendarId: selectedTimeslot.calendarId,
                startTime: selectedTimeslot.startTime,
                endTime: selectedTimeslot.endTime,
                userId: userSession.userId,
              });
              funnelApi.updateContext({
                appointmentId: result.AppointmentCreate.id,
              });
            }
          },
        }),

        confirm_phone: funnelPage(ConfirmPhoneNumber, {
          onEntry: async ({ context }) => {
            await api.UserStartIdConfirmationSMS({
              phoneNumber: context.userSession.phoneNumber,
            });
          },
          onExit: async ({ funnelApi, context: { selectedTimeslot, userSession } }) => {
            funnelApi.updateContext({
              userSession: {
                ...userSession,
                phoneConfirmed: true,
              },
            });

            try {
              const result = await api.AppointmentCreate({
                reason: 'SCREENING',
                calendarId: selectedTimeslot.calendarId,
                startTime: selectedTimeslot.startTime,
                endTime: selectedTimeslot.endTime,
                userId: userSession.userId,
              });
              funnelApi.updateContext({
                appointmentId: result.AppointmentCreate.id,
              });
            } catch (err: unknown) {
              if (err?.toString().includes('CODE_3001_APPOINTMENT_DATE_NOT_AVB')) {
                return ConfirmPhoneNumberErrors.AppointmentNotAvailable;
              }
              throw err;
            }
          },
          actions: {
            confirmPhoneNumber: async (phoneNumber, code) => {
              try {
                const result = await api.UserCompleteIdConfirmationSMS({
                  phoneNumber,
                  code,
                });
                return result.UserCompleteIdConfirmation.success;
              } catch (error) {
                return false;
              }
            },
            sendSMSCode: async (phoneNumber, { context, funnelApi }) => {
              await api.UserStartIdConfirmationSMS({ phoneNumber });
              funnelApi.updateContext({
                userSession: {
                  ...context.userSession,
                  phoneNumber,
                },
              });
            },
          },
        }),
        confirmation: funnelPage(BookingSummary, { terminal: true }),
      },
    },
    routingRules: {
      registration: {
        registration: ({ context: { flowVariant, partnerClientSession }, error }) => {
          if (error === RegistrationErrors.PhoneOrEmailAlreadyInUse) {
            return 'registration/registration';
          } else if (flowVariant === 'phone-already-registered') return 'registration/confirm_phone_number';
          else if (!partnerClientSession) {
            return 'registration/state_of_residency';
          } else return 'registration/birth_date';
        },
        state_of_residency: ({ context: { stateOfResidency } }) => {
          if (stateOfResidency != 'TX') return 'registration/not_texas';
          return 'registration/birth_date';
        },

        birth_date: ({ context: { notEligibleReason } }) => {
          if (notEligibleReason === 'invalid-age') {
            return 'registration/thank_you';
          }
        },
        height_weight: ({ context: { notEligibleReason } }) => {
          if (['low-bmi', 'very-low-bmi'].includes(notEligibleReason)) {
            return 'registration/thank_you';
          }
        },
        sex_assigned: ({ data }) =>
          data.value === SexAssignedAtBirth.Male ? 'registration/current_medications' : 'registration/pregnancy',
        pregnancy: ({ context: { eligibleFor } }) =>
          eligibleFor === ProductSelection.CoachingOnly ? 'checkout/recommendation' : null,
        current_medications: ({ context: { eligibleFor } }) =>
          eligibleFor === ProductSelection.CoachingOnly ? 'checkout/recommendation' : null,
        glp1: ({ context: { eligibleFor } }) =>
          eligibleFor === ProductSelection.CoachingOnly ? 'checkout/recommendation' : 'checkout/choose_program',
        eligibility: ({ context: { notEligibleReason, eligibleFor } }) => {
          if (['addiction', 'eating-disorder'].includes(notEligibleReason)) return 'registration/thank_you';
          else if (eligibleFor === ProductSelection.CoachingOnly) return 'checkout/recommendation';
        },
        comorbidity: ({ context: { eligibleFor } }) =>
          eligibleFor === ProductSelection.CoachingOnly ? 'checkout/recommendation' : null,
      },
      checkout: {
        checkout: ({ data }) => {
          if (data.productChanged) {
            return 'checkout/checkout';
          }
          if (data.didSelectBooking) {
            return 'checkout/pick_timeslot';
          }
        },
        recommendation: () => {
          return 'checkout/checkout';
        },
        choose_program: () => {
          return 'checkout/checkout';
        },
        pay: () => {
          return 'checkout/confirm_membership_phone';
        },
      },
    },
    defaultPageWidth: 720,
    initialContext,
    defaultLoader: {
      component: () => null,
      progressBar: false,
    },
  });

const comorbidityQuestionsEvaluation = (
  data: FunnelPageData<typeof ComorbidityQuestionsForCheckout>,
  bmi: number,
): ProductSelection => {
  if (bmi >= 30) {
    return ProductSelection.CoachingAndMaybeMedication;
  }
  if (anyTicked(data)) {
    return ProductSelection.MedicationAndCoaching;
  } else {
    return ProductSelection.CoachingOnly;
  }
};
