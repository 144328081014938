import bookingImage from '@assets/images/request-callback.png';
import { useIsMobileViewport } from '@hooks/useIsMobileViewport';
import { Checkbox, Group, Image, Paper, Stack, Text, UnstyledButton } from '@mantine/core';
import { AddressFormUS } from '@pages/checkout/AddressFormUS';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { BottomScreenContainer } from '@shared/components/layout/BottomScreenContainer';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { TrustBoxMicroTrustScore } from '@shared/components/TrustBox';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { Address } from '@shared/gql/sdk';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import IFunnelContext, { ProductSelection } from 'src/FunnelContext';

import { Benefits } from './Benefits';
import { MembershipSummary } from './MembershipSummary';
import { SwitchProgram } from './SwitchProgram';
import { YourRecommendedMembership } from './YourRecommendedMembership';

export const CheckoutUS: FunnelPageComponent<
  {
    address: Address;
    selectedProduct: ProductSelection;
    productChanged?: boolean;
    didSelectBooking?: boolean;
  },
  IFunnelContext
> = ({
  data: { address, didSelectBooking },
  context: { selectedProduct, selectedProductPriceInfo: price, eligibleFor, stateOfResidency },
  funnelApi,
}) => {
  const [bookingSelected, setBookingSelected] = useState(didSelectBooking);
  const { t } = useTranslation();
  const shippingAddress = useRef(address);
  const addressFormRef = useRef<{ validate: () => boolean }>();

  if (!address) {
    address = {};
  }

  if (!address.district) {
    address.district = stateOfResidency;
  }

  return (
    <FullScreenContainer>
      <StepTitle title={<Trans i18nKey={'startYourJourney'} />} />
      <Stack gap="lg">
        <Stack gap={'sm'}>
          <YourRecommendedMembership
            price={price}
            selectedProduct={selectedProduct}
            selected={!bookingSelected}
            onClick={() => {
              setBookingSelected(false);
            }}
            compact
            label={t('selectHowYouWantToStart')}
          />
          <BookingCard selected={bookingSelected} onClick={() => setBookingSelected(true)} />

          {eligibleFor === ProductSelection.MedicationAndCoaching && (
            <SwitchProgram
              productChanged={true}
              selectedProduct={selectedProduct}
              onClick={() => {
                setBookingSelected(false);
                funnelApi.next({
                  selectedProduct:
                    selectedProduct == ProductSelection.CoachingOnly
                      ? ProductSelection.MedicationAndCoaching
                      : ProductSelection.CoachingOnly,
                  productChanged: true,
                  address: shippingAddress.current,
                });
              }}
            />
          )}
        </Stack>
        {!bookingSelected ? (
          <>
            <AddressFormUS
              ref={addressFormRef}
              label={t('deliveryAddress')}
              address={address}
              onChange={(changedAddress) => {
                shippingAddress.current = changedAddress;
              }}
            />
            <MembershipSummary price={price} title={t('orderSummary')} />
          </>
        ) : null}

        <TrustBoxMicroTrustScore />
        {!bookingSelected ? <Benefits selectedProduct={selectedProduct} /> : null}
      </Stack>
      <BottomScreenContainer>
        {bookingSelected ? (
          <NextButton
            onClick={() =>
              funnelApi.next({ selectedProduct, address: shippingAddress.current, didSelectBooking: bookingSelected })
            }
            label={t('book')}
          />
        ) : (
          <NextButton
            onClick={() => {
              const addressIsValid = addressFormRef?.current?.validate();
              console.log('zaza', addressIsValid, selectedProduct);
              if (selectedProduct === ProductSelection.MedicationAndCoaching ? addressIsValid : true) {
                funnelApi.next({
                  address: shippingAddress.current,
                  selectedProduct: selectedProduct,
                  didSelectBooking: bookingSelected,
                });
              }
            }}
            label={t('Checkout')}
          />
        )}

        <BackButton onClick={() => funnelApi.back()} />
      </BottomScreenContainer>
    </FullScreenContainer>
  );
};

const BookingCard = ({ selected, onClick }: { selected: boolean; onClick: () => void }) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobileViewport();
  const stackRef = useRef(null);

  return (
    <UnstyledButton onClick={onClick}>
      <Paper data-selected={selected} p={'md'}>
        <Group align="center" justify="center" wrap="nowrap">
          <Image src={bookingImage} w={62} h={74} />
          <Stack ref={stackRef} style={{ flex: 1 }} justify="flex-start" gap={0}>
            <Group justify="space-between" align="flex-start">
              <Text fw={isMobile ? 600 : 400} lh={1.1} size={isMobile ? '18px' : '24px'} flex={1}>
                {t('bookAFreeCoachingSession')}
              </Text>
              <Checkbox checked={selected} />
            </Group>
            <Text fw={400} data-secondary>
              <Trans
                i18nKey={'notReadyYetTryAFreeSession'}
                components={{
                  highlight: <span style={{ fontWeight: 600 }} />,
                }}
              />
            </Text>
          </Stack>
        </Group>
      </Paper>
    </UnstyledButton>
  );
};
