import { useIsMobileViewport } from '@hooks/useIsMobileViewport';
import { Card, Checkbox, Divider, Group, Stack, Text } from '@mantine/core';
import { EmblaIconName, Icon } from '@shared/components/Icon';
import { CountryIso3166, FinancialPrice } from '@shared/gql/sdk';
import { formatAmount, getProductNameByProduct, isEmblaIconName } from '@shared/helpers';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductSelection } from 'src/FunnelContext';
import { resolveCountry, resolveIntl } from 'src/resolveIntl';

import { t } from '@t';

type Variant = 'price-top' | 'program-top';

interface IProps {
  compact?: boolean;
  productSelection: ProductSelection;
  highlightSelected?: boolean;
  selected?: boolean;
  price: FinancialPrice;
  variant?: Variant;
  hidePrice?: boolean;
}

interface BulletItem {
  icon: EmblaIconName;
  label: string;
}

export const ProductCard: FunctionComponent<IProps> = ({
  price,
  productSelection,
  compact,
  highlightSelected,
  selected,
  variant = 'program-top',
  hidePrice = false,
}) => {
  return (
    <Card data-selected={highlightSelected && selected} p={'md'} w={'100%'}>
      <Top
        productSelection={productSelection}
        price={price}
        selected={selected}
        variant={variant}
        hidePrice={hidePrice}
      />
      {!compact && (
        <>
          <Divider my="md" />
          <BulletPoints items={getItems(productSelection)} />
        </>
      )}
    </Card>
  );
};

const Top: React.FC<{
  productSelection: ProductSelection;
  price: FinancialPrice;
  selected: boolean;
  variant: Variant;
  hidePrice: boolean;
}> = ({ productSelection, price, selected, variant, hidePrice }) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobileViewport();
  const { country } = resolveIntl();

  const renderAmount = () => {
    const intervalCount = parseInt(price.paymentIntervalCount, 10);
    const quantifier =
      intervalCount > 1 ? ` ${t('forMonths', { intervalCount: intervalCount })}` : ` / ${t('monthShort')}`;
    const externalFees =
      price.product?.externalServiceFees
        ?.map((f) => f.amount)
        .filter(Boolean)
        .reduce((acc, curr) => {
          return acc + curr;
        }, 0) || 0;

    return price?.campaignAmount ? (
      <Group>
        <Text fw={600} data-secondary size="lg">
          {formatAmount(price.campaignAmount + externalFees, price.currency)}
          {price.campaignText ? `/${price.campaignText}` : quantifier}
        </Text>
        {country !== CountryIso3166.Us ? (
          <Text td="line-through" fw={400} data-tertiary size="lg">
            {formatAmount(price.amount, price.currency)}
            {quantifier}
          </Text>
        ) : null}
      </Group>
    ) : (
      <Text fw={600} data-secondary size="lg">
        {formatAmount(price.amount + externalFees, price.currency)}
        {quantifier}
      </Text>
    );
  };

  const getPriceIconName = (productSelection: ProductSelection, priceIconName?: string): EmblaIconName => {
    if (priceIconName && isEmblaIconName(priceIconName)) {
      return priceIconName;
    }
    return productSelection === ProductSelection.MedicationAndCoaching ? 'icon-fi-rr-medicine' : 'icon-fi-rr-users';
  };

  const getProductShortDescription = (productSelection: ProductSelection, shortDescription?: string): string => {
    if (shortDescription) {
      return shortDescription;
    }
    return productSelection === ProductSelection.MedicationAndCoaching
      ? t('medicationSupportedPlan')
      : t('comprehensiveCoachingPlan');
  };

  return (
    <Group wrap="nowrap">
      <Card
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        data-background={true}
        px={12}
        py={variant === 'program-top' ? 16 : 8}
      >
        <Icon name={getPriceIconName(productSelection, price?.icon)} size={variant === 'program-top' ? 36 : 24} />
      </Card>
      <Stack style={{ flexGrow: 1 }} gap={0}>
        {variant === 'program-top' ? (
          <>
            <Group justify="space-between" wrap="nowrap" align="top">
              <Text fw={isMobile ? 600 : 400} size={isMobile ? '18px' : '24px'}>
                {getProductNameByProduct(price.product)}
              </Text>
              {selected !== undefined && <Checkbox checked={selected} readOnly />}
            </Group>
            <Text fw={400} data-secondary>
              {getProductShortDescription(productSelection, price.product?.shortDescription)}
            </Text>
            {!hidePrice ? renderAmount() : null}
          </>
        ) : (
          <>
            {!hidePrice ? (
              <Group justify="space-between" wrap="nowrap" align="top">
                {renderAmount()}
              </Group>
            ) : null}
            <Text fw={400} data-secondary>
              {getProductNameByProduct(price.product)}
            </Text>
          </>
        )}
      </Stack>
    </Group>
  );
};

export const BulletPoints: React.FC<{
  items: BulletItem[];
}> = ({ items = [] }) => {
  return (
    <Stack>
      {items.map(({ icon, label }) => (
        <Group style={{ flexWrap: 'initial' }} key={label} gap="md" align="center">
          <Icon name={icon} data-secondary size={20} />
          <Text fw={400} data-secondary>
            {label}
          </Text>
        </Group>
      ))}
    </Stack>
  );
};

export const getItems = (productSelection: ProductSelection): BulletItem[] => {
  const country = resolveCountry();
  switch (country) {
    case CountryIso3166.Dk: {
      return getDKItems(productSelection);
    }
    case CountryIso3166.Gb: {
      return getGBItems(productSelection);
    }
    case CountryIso3166.Us: {
      return getUSItems(productSelection);
    }
  }
};

const getGBItems = (productSelection: ProductSelection): BulletItem[] => {
  return productSelection === ProductSelection.MedicationAndCoaching
    ? [
        {
          label: t('coachPsychologistAndDoctor'),
          icon: 'icon-fi-rr-medicine',
        },
        {
          label: t('1to1HealthGuidance'),
          icon: 'icon-fi-rr-journey',
        },
        {
          label: t('fullAccessToEmblaApp'),
          icon: 'icon-fi-rr-mobile-notch',
        },
        {
          label: t('monthlyFreeMedicationShipments'),
          icon: 'icon-fi-rr-journey', // update ship-fast icon
        },
      ]
    : [
        {
          label: t('dedicatedHealthCoach'),
          icon: 'icon-fi-rr-medicine',
        },
        {
          label: t('1to1NutrationAndWellBeingGuidance'),
          icon: 'icon-fi-rr-journey',
        },
        {
          label: t('personalisedActionPlanForTrackingProgress'),
          icon: 'icon-fi-rr-clipboard-list-check',
        },
        {
          label: t('fullAccessToEmblaApp'),
          icon: 'icon-fi-rr-mobile-notch',
        },
      ];
};

const getUSItems = (productSelection: ProductSelection): BulletItem[] => {
  return productSelection === ProductSelection.MedicationAndCoaching
    ? [
        {
          label: t('glp1PrescriptionManagement'),
          icon: 'icon-fi-rr-medicine',
        },
        {
          label: t('oneToOneBehaviorAndHealthCoaching'),
          icon: 'icon-fi-rr-journey',
        },
        {
          label: t('fullAccessToEmblaHealthApp'),
          icon: 'icon-fi-rr-mobile-notch',
        },
      ]
    : [
        {
          label: t('personallyAssignedHealthCoach'),
          icon: 'icon-clinic',
        },
        {
          label: t('1to1coachingInNutritionHabitsMindset'),
          icon: 'icon-fi-rr-journey',
        },

        {
          label: t('fullAccessToEmblaHealthApp'),
          icon: 'icon-fi-rr-mobile-notch',
        },
      ];
};

const getDKItems = (productSelection: ProductSelection): BulletItem[] => {
  return productSelection === ProductSelection.MedicationAndCoaching
    ? [
        {
          label: t('oneToOneBehavioralCoaching'),
          icon: 'icon-clinic',
        },
        {
          label: t('glp1Medicine'),
          icon: 'icon-fi-rr-medicine',
        },
        {
          label: t('nurseDoctorPsychologist'),
          icon: 'icon-fi-rr-heart',
        },
        {
          label: t('safeWithdrawal'),
          icon: 'icon-fi-rr-journey',
        },
      ]
    : [
        {
          label: t('oneToOneBehavioralCoaching'),
          icon: 'icon-clinic',
        },
        {
          label: t('videoAppointmentsAndChat'),
          icon: 'icon-fi-rr-comments',
        },
        {
          label: t('personalDevelopmentPlan'),
          icon: 'icon-fi-rr-journey',
        },
        {
          label: t('coursesLogRecipes'),
          icon: 'icon-social-handshake',
        },
      ];
};
