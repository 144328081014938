import { env } from '@env';
import { Button, Stack, Text } from '@mantine/core';
import { AddToCalendarButton } from '@shared/components/buttons/AddToCalendarButton';
import { AppButton } from '@shared/components/buttons/AppButton';
import { FeedbackLink } from '@shared/components/buttons/FeedbackLink';
import { Icon } from '@shared/components/Icon';
import { KeyValueInfoCard } from '@shared/components/KeyValueInfoCard';
import { FullScreenContainer } from '@shared/components/layout/FullScreenContainer';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { getRootPath } from '@shared/helpers';
import dayjs from 'dayjs';
import IFunnelContext from 'src/FunnelContext';

import { t } from '@t';

export const BookingSummary: FunnelPageComponent<undefined, IFunnelContext> = ({ funnelApi, context }) => {
  const { serverRoot } = env || { serverRoot: 'https://app-joinembla.com' };
  const { selectedTimeslot, appointmentId } = context;
  const { embedded, partnerClientSession } = context;

  let topText = t('oneLastThing');
  let title = t('downloadAppToParticipateInConsultation');
  if (embedded) {
    topText = t('bookingConfirmed');
    title = t('youWillBeAttendingConsulationViaApp');
  }

  const date = dayjs(selectedTimeslot?.startTime).format('LL');
  const startTime = dayjs(selectedTimeslot?.startTime).format('LT');
  const endTime = dayjs(selectedTimeslot?.endTime).format('LT');
  return (
    <FullScreenContainer justify="space-between" pb="md">
      <Stack gap="lg" py="md">
        <Text ta="center" fw={700}>
          {topText}
        </Text>
        <StepTitle mt={0} mb={0} title={title} />

        <KeyValueInfoCard
          title={t('yourBookingConfirmation')}
          config={[
            {
              icon: 'icon-fi-rr-users',
              label: t(selectedTimeslot.userRole.toLowerCase()),
              value: selectedTimeslot.name,
            },
            {
              icon: 'icon-fi-rr-calendar-2',
              label: t('date'),
              value: date,
            },
            {
              icon: 'icon-fi-rr-clock',
              label: t('time'),
              value: `${startTime} - ${endTime}`,
            },
            {
              icon: 'icon-fi-rr-mobile-notch',
              label: t('how'),
              value: t('videoConsultation'),
            },
          ]}
          footer={
            <AddToCalendarButton
              variant={'light'}
              isEmbeddedInApp={embedded}
              serverRoot={serverRoot}
              appointmentId={appointmentId}
              iconPosition="left"
              size="sm"
            />
          }
        />
      </Stack>
      <FeedbackLink embedded={embedded} source={context.source} />
      {embedded || !!partnerClientSession ? (
        <Button
          leftSection={<Icon size={20} name={'icon-fi-rr-check'} />}
          variant="transparent"
          onClick={() => {
            if (embedded) {
              // on this url the app knows that it's time to close webview and refetch user data
              window.location.href = `${getRootPath()}/app-complete`;
            } else {
              funnelApi.next(undefined);
            }
          }}
        >
          {t('finish')}
        </Button>
      ) : (
        <AppButton />
      )}
    </FullScreenContainer>
  );
};
