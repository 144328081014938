import { useIsMobileViewport } from '@hooks/useIsMobileViewport';
import { Card, Group, SimpleGrid, Stack, Text, UnstyledButton } from '@mantine/core';
import { Icon } from '@shared/components/Icon';
import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  options: string[];
  selected?: string;
  setTime: (time: string) => void;
  durationInMinutes: number;
}

export const TimeSelector: FunctionComponent<IProps> = ({ options, selected, setTime, durationInMinutes }) => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobileViewport();
  return (
    <Stack gap={'sm'}>
      <Group justify="space-between">
        <Text fw={400} size={'lg'}>
          {t('selectTime')}
        </Text>
        {durationInMinutes ? (
          <Group align="center" gap="xs">
            <Icon name="icon-fi-rr-clock" data-tertiary />
            <Text data-tertiary fw={400} size={'md'}>
              {`${durationInMinutes}min`}
            </Text>
          </Group>
        ) : null}
      </Group>
      <SimpleGrid cols={isMobile ? 4 : 6} spacing={6}>
        {options.map((opt) => {
          const isSelected = selected === opt;
          const hour = Number(opt.split(':')?.[0]);
          const minute = Number(opt.split(':')?.[1]);
          return (
            <UnstyledButton
              m="xs"
              key={opt}
              onClick={() => {
                setTime(opt);
              }}
              styles={{
                root: {
                  borderRadius: '8px',
                },
              }}
            >
              <Card py={'lg'} px={'sm'} radius={'md'} role="button" data-selected={isSelected}>
                <Text data-selectable-secondary fw={400} ta={'center'}>
                  {dayjs().set('hour', hour).set('minute', minute).format('LT')}
                </Text>
              </Card>
            </UnstyledButton>
          );
        })}
      </SimpleGrid>
    </Stack>
  );
};
